import { useEffect } from 'react'

import * as Sentry from '@sentry/nextjs'

import { useRouter } from 'next/router'
import Script from 'next/script'

import { useAppContext } from '~/lib/state_management/context/appContext'

import { isBrowser } from 'browser-or-node'
import Cookies from 'js-cookie'
import { User } from '~/lib/api/models'
import { analytics } from '~/lib/segment'

const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID
const UNBOUNCE_ID = process.env.NEXT_PUBLIC_UNBOUNCE_ID

// Checking for existence of window seems to be the recommended approach for running
// effects that must run only once:
// https://github.com/vercel/next.js/issues/5354#issuecomment-520305040
if (isBrowser) {
  // Updates the Facebook Pixel messageId to deduplicate events.
  // See this Linear ticket for more info
  // https://linear.app/tinyhood-dev/issue/TIN-955/deduplicate-frontend-facebook-subscription-created-conversion-events
  const fbpDedupeEvents = function ({ payload, next }: any) {
    switch (payload.obj.event) {
      case 'Subscription Created Conversion':
      case 'Signed Up':
        payload.obj.messageId = payload.obj.properties.transaction_id
        // first name and last name are already automatically sent to facebook in a hashed format
        // so don't send them again as plain / plain text properties as that violates their personal data policy
        // https://linear.app/tinyhood-dev/issue/TIN-1022/remove-first-name-and-last-name-from-facebook-pixel-events
        delete payload.obj.properties.first_name
        delete payload.obj.properties.last_name
    }
    next(payload)
  }

  analytics.addDestinationMiddleware('Facebook Pixel', fbpDedupeEvents)
}

declare global {
  interface Window {
    _ubaq: any
  }
}

export async function getAnonymousId() {
  let anonymousId = Cookies.get('ajs_anonymous_id')
  if (!anonymousId) anonymousId = (await getAnonymousIdFromSegment()) ?? ''
  return anonymousId
}

export async function getAnonymousIdFromSegment() {
  return (await analytics.user())?.anonymousId()?.toString()
}

export async function resetAnalytics() {
  return await analytics.reset()
}

export function trackUserId(userId: string | undefined) {
  Sentry.setUser({ id: userId })
}

export function trackPage(path: string) {
  const url = isBrowser ? new URL(path, window.location.origin).href : path

  // Segment
  analytics.page(path)
}

export function trackButtonClick(id: string, area: string, planTitle?: string) {
  const properties: Record<string, string> = {
    id,
    area,
  }
  if (planTitle) {
    properties['plan'] = planTitle
  }
  analytics.track('Button Click', properties)
}

export async function trackPurchase({
  email,
  customer_id,
  first_name,
  last_name,
  content_id,
  content_name,
  value,
  currency,
  transaction_id,
}: {
  email?: string
  customer_id?: string
  first_name?: string
  last_name?: string
  content_id: string
  content_name: string
  value: number
  currency: string
  transaction_id: string
}) {
  analytics.track(
    'Subscription Created Conversion',
    {
      customer_id,
      email,
      first_name,
      last_name,
      content_id,
      content_name,
      value,
      currency,
      transaction_id,
      products: [
        {
          product_id: content_id, // duplicating data inside a products[] because "Google Analytics 4 Web" Purchase events require an array of items
          name: content_name,
        },
      ],
    },
    {
      traits: {
        facebook_external_id: customer_id, // Our Facebook Pixel destination uses this as the external id (e.g. user id / anonymous id)
      },
      integrations: {
        All: false,
        'Facebook Pixel': true,
        'Google AdWords New': true, // "Google Ads (Gtag)" destination
        'Google Analytics 4 Web': true,
        'Bing Ads': true,
      },
    }
  )

  // unbounce conversion
  window._ubaq = window._ubaq || []
  window._ubaq.push(['trackGoal', 'convert'])
  ;(function () {
    var ub_script = document.createElement('script')
    ub_script.type = 'text/javascript'
    ub_script.src =
      ('https:' == document.location.protocol ? 'https://' : 'http://') +
      'd3pkntwtp2ukl5.cloudfront.net/uba.js'
    var s = document.getElementsByTagName('script')[0]
    s?.parentNode?.insertBefore(ub_script, s)
  })()
}

export async function identifyUser(user?: User | null) {
  if (!user) return

  await analytics.identify(user.id, {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
  })
}

export async function trackSignup(user: User) {
  identifyUser(user)

  // In Segment we map "Signed Up" to a Facebook "CompleteRegistration" and in Google Ads to a "signup conversion"
  analytics.track(
    'Signed Up',
    {
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      transaction_id: user.id,
    },
    {
      traits: {
        facebook_external_id: user.id, // Our Facebook Pixel destination uses this as the external id (e.g. user id / anonymous id)
      },
      integrations: {
        All: false,
        'Facebook Pixel': true,
        'Google AdWords New': true, // "Google Ads (Gtag)" destination
        'Google Analytics 4 Web': true,
        'Bing Ads': true,
      },
    }
  )
}

export async function trackSignupProgress(email: string) {
  // Omit the user id here since the user doesn't have a user id yet
  // This sets the attributes on the current anonymous Segment user and will be carried through
  // when the user creates an account and we set the user id
  // https://linear.app/tinyhood-dev/issue/TIN-1326/frontend-causing-duplicate-users-in-segment
  // Also only forward this to Customer.io to prevent other downstream destinations (i.e. MixPanel)
  // from creating multiple users with the same email address

  const segmentUser = await analytics.user()

  // If segment has a user id, don't fire the identify as it could swap their email
  if (segmentUser.id()) {
    Sentry.withScope((scope) => {
      scope.setTag('user_id', segmentUser.id())
      scope.setTag('anonymous_id', segmentUser.anonymousId())
      Sentry.captureMessage('trackSignupProgress called with existing segment user_id')
    })
    return
  }

  await analytics.identify(
    {
      email,
    },
    {
      integrations: {
        All: false,
        'Customer.io (Actions)': true,
      },
    }
  )
}

export async function track(eventName: string) {
  analytics.track(eventName)
}

export default function Analytics() {
  const router = useRouter()
  const asPath = router.asPath
  const pathname = router.pathname
  const query = router.query

  const appContext = useAppContext()

  // Track changes to the user's id
  useEffect(() => {
    trackUserId(appContext.user?.id)
  }, [appContext.user?.id])

  // Identify user for Segment purposes
  useEffect(() => {
    identifyUser(appContext.user)
  }, [appContext.user])

  // Track client-side page views
  useEffect(() => {
    trackPage(asPath)
  }, [asPath])

  const signupCompleted = router.query.signup_completed
  useEffect(() => {
    if (signupCompleted && appContext.user) {
      // track the sign up
      trackSignup(appContext.user)

      // remove the signup_completed param
      delete query.signup_completed
      router.replace({ pathname, query }, undefined, {
        shallow: true,
      })
    }
  }, [signupCompleted, appContext.user, pathname, query, router])

  return (
    <>
      {/* HOTJAR */}
      <Script id="hotjar">
        {`
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:${HOTJAR_ID},hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
      </Script>

      {/* UNBOUNCE PIXEL */}
      <Script id="unbounce" src={`https://${UNBOUNCE_ID}.js.ubembed.com`} />
    </>
  )
}
